<template>
	<nav>
		<!-- Logo -->
		<img class="img_nav" src="https://web.inmotech.co/wp-content/uploads/2024/07/Recurso-2.png" alt="">

		<!-- Menú hamburguesa para móviles -->
		<div class="menu-hamburger" @click="toggleMenu">
			<span></span>
			<span></span>
			<span></span>
		</div>
		<!-- Menú principal -->
		<div :class="['container_nav_links', { 'show-menu': menuOpen }]">
			<div v-for="(item, i) in links" :key="i" class="menu-item" @mouseover="showDropdown = item.name"
				@mouseleave="showDropdown = null">
				<template v-if="item.children">
					<span class="menu-link dropdown-trigger">
						{{ item.text }}
						<i class="material-icons">expand_more</i>
					</span>
					<div v-if="showDropdown === item.name" class="dropdown-menu">
						<router-link v-for="(child, j) in item.children" :key="j" class="dropdown-item"
							:to="child.path">
							{{ child.name }}
						</router-link>
					</div>
				</template>
				<template v-else>
					<router-link class="menu-link" :to="item.hash ? `${item.path}${item.hash}` : item.path">
						{{ item.text }}
					</router-link>
				</template>
			</div>
		</div>

		<!-- Contacto y usuario -->
		<div class="container_contact_nav">
			<div class="contact_info">
				<i class="material-icons-outlined">phone_in_talk</i>
				<p>(604)4442949</p>
			</div>
			<div class="user_login">
				<a class="btn-icon-login-register">
					<i class="material-icons-outlined">account_circle</i>
				</a>
			</div>
			<div class="nav_btn_inmotech">
				<a href="">Inmotech</a>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'MainHeader',
	data() {
		return {
			menuOpen: false,
			showDropdown: null,
			links: this.$router.options.routes.filter(route => route.text),
		};
	},
	methods: {
		toggleMenu() {
			this.menuOpen = !this.menuOpen;
		},
		closeMenu() {
			this.menuOpen = false;
		}
	},
	watch: {
		// Detecta el cambio de ruta y cierra el menú automáticamente
		"$route"() {
			this.closeMenu();
		}
	}
};
</script>
<style scoped>
/* Estilos generales */
nav {
	background-color: #152733;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
}

.img_nav {
	width: 22%;
	margin: 0.6% 0 0.6% 2%;
}

a {
	text-decoration: none;
}

.container_nav_links {
	width: 82%;
	display: contents;
	font-size: 13px;
	font-weight: 600;
}

.menu-item {
	position: relative;
}


.container_nav_links>a {
	color: white;
	margin-left: 3%;
}

.container_nav_links>a:hover {
	color: #c7932e;
}


.menu-link {
	color: white;
	margin-left: 3%;
	text-decoration: none;
	white-space: nowrap;
}

.menu-link:hover {
	color: #c7932e;
}

.container_contact_nav {
	width: 20%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #c7932e;
	font-size: 13px;
	padding: 10px;
}

.contact_info {
	display: flex;
	align-items: center;
}

.contact_info i {
	font-size: 18px;
}

.contact_info p {
	font-weight: 500;
	margin: 0;
	padding: 0;
}



.nav_btn_inmotech {
	padding: 12px 15px;
	background-color: #c7932e;
	border-radius: 4px;
}

.nav_btn_inmotech>a {
	color: #fff;
	font-weight: 600;
}

.menu-hamburger {
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 20px;
	cursor: pointer;
}

.menu-hamburger span {
	background: #fff;
	height: 3px;
	width: 100%;
	border-radius: 2px;
}

/* Estilos del menú desplegable */
.dropdown-trigger {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 5px;
	/* Espacio entre el texto y el ícono */
}

.dropdown-trigger i {
	font-size: 16px;
	transition: transform 0.5s ease;
}

.menu-item:hover .dropdown-trigger i {
	transform: rotate(180deg);
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	background: #152733;
	border-radius: 1px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
	padding: 10px;
	display: flex;
	flex-direction: column;
	min-width: 150px;
	z-index: 1000;
	text-align: left !important;
}

.dropdown-item {
	text-decoration: none;
	color: white !important;
	padding: 10px 20px;
	display: inline-block;
	font-size: 13px;
	border-bottom: solid #0d1920 0.5px;
}

.dropdown-item:hover {
	background: #213f47c9;
	color: #c7932e !important;
}

.container_contact_nav {
	width: 20%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #c7932e;
	font-size: 13px;
	padding: 10px;
}

.contact_info {
	display: flex;
	align-items: center;
}

.contact_info i {
	font-size: 18px;
}

.contact_info>p {
	font-weight: 600;
	margin-left: 3%;
}

.nav_btn_inmotech {
	padding: 12px 15px;
	background-color: #c7932e;
	border-radius: 4px;
}

.nav_btn_inmotech>a {
	color: #fff;
	font-weight: 600;
}

/* Diseño responsive */
@media (max-width: 1100px) {
	nav{
		padding: 10px;
	}

	/* Ajustes generales */
	.img_nav {
		width: 30%;
	}

	.container_contact_nav {
		display: none;
	}

	/* Mostrar menú hamburguesa */
	.menu-hamburger {
		display: flex;
	}

	/* Ocultar menú de enlaces */
	.container_nav_links {
		position: absolute;
		top: 60px;
		left: 0;
		width: 100%;
		background: #152733;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 15px;
		transform: translateY(-200%);
		transition: transform 0.3s ease-in-out;
		z-index: 1000;
	}

	/* Mostrar menú cuando se activa */
	.container_nav_links.show-menu {
		transform: translateY(0);
	}

	.menu-item {
		text-align: center;
	}

	.menu-link {
		font-size: 16px;
		padding: 10px;
		display: block;
	}
}

@media (max-width: 600px) {
	.img_nav {
		width: 60%;
		text-align: center;
	}

	.menu-link {
		font-size: 14px;
	}

	.container_nav_links {
		top: 50px;
		padding: 10px;
	}
}
</style>
