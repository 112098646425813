import Home from '@/components/view/Home'
import AboutUs from '@/components/view/AboutUs'
import PQRS from '@/components/view/PQRS'
import InmueblesView from '@/components/view/inmueble/InmueblesView'
// import InmueblesStore from '@/components/view/inmueble/InmueblesStore'
// import InmuebleDetail from '@/components/view/inmueble/InmuebleDetail'
// import BlogsView from '@/components/view/blog/BlogsView'
// import BlogArchive from '@/components/view/blog/BlogArchive'
import WorkTeam from '../components/view/workTeam.vue'
import Contactanos from '../components/view/Contactanos.vue'
import Postularse from '../components/view/Postularse.vue'
import Abogados from '../components/view/Abogados.vue'
import Preguntas from '../components/view/Preguntas.vue'
import TerminosCondiciones from '../components/view/TerminosCondiciones.vue'
import PoliticasPrivacidad from '../components/view/PoliticasPrivacidad.vue'

// import Carousel from '../components/view/Carousel.vue'

const routes = [
  {
    path: '/',
    name: 'inicio',
    text: 'Inicio',
    component: Home
  },
  {
    path: '/nosotros/',
    text: 'Nosotros',
    name: 'nosotros',
    component: InmueblesView,
    children: [
      {
        path: '/quienes-somos/',
        name: 'Quienes Somos?',
        component: AboutUs
      },
      {
        path: '/equipo-trabajo/',
        name: 'equipo-trabajo',
        component: WorkTeam,
      },
      {
        path: '/abogados-consultores/',
        name: 'abogados-consultores',
        component: Abogados,
      }
    ]
  },
  {
    path: '/atencion-cliente/',
    text: 'Atención al Cliente',
    name: 'atencion-cliente',
    component: InmueblesView,
    children: [
      {
        path: '/pqrs/',
        name: 'PQRS',
        component: PQRS
      },
      {
        path: '/contactanos/',
        name: 'Contáctanos',
        component: Contactanos,
      },
      {
        path: '/postularse/',
        name: 'postularse',
        component: Postularse,
      }

    ]
  },

  {
    path: '/preguntas-frecuentes/',
    name: 'preguntas-frecuentes',
    text: 'Preguntas Frecuentes',
    component: Preguntas
  },
  {
    path: '/terminos-condiciones/',
    name: 'terminosycondiciones',
    component: TerminosCondiciones
  },
  {
    path: '/politicas-privacidad/',
    name: 'politicas-privacidad',
    component: PoliticasPrivacidad
  },
]
export { routes }