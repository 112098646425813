<template>
  <div id="Home">
    <!-- <Inmueble/> 
   <Blog/>
    <Contact/> -->
    <!-- Banner <Banner/>-->

    <div class="banner" data-aos="fade-down" data-aos-duration="2000">
      <div class="header">
        <div class="container_banner">
          <div>
            <div class="container_text_banner">
              <p>Encuentra Tu Hogar Con Grupo Empresarial MI</p>
              <span>Busca ahora el inmueble que tanto has soñado!</span>
            </div>
          </div>
          <div class="container_search_banner">
            <div class="tabs">
              <button class="tab active">Todos</button>
              <button class="tab">Amoblado</button>
              <button class="tab">Arriendo</button>
              <button class="tab">Venta</button>
            </div>
            <div class="search-bar">
              <div class="search-form">
                <input type="text" class="search-input" placeholder="Buscar..." />
                <div class="container_dropdown">
                  <select class="dropdown first_Select">
                    <option>Cuartos</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <select class="dropdown">
                    <option>Baños</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
                <button class="search-button">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seccion de servicios -->
    <section class="section_services">
      <div class="title_seccion_services">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          Servicios</h1>
        <hr data-aos="fade-right" data-aos-duration="2000">
      </div>
      <div class="container_cards_services" data-aos="fade-up" data-aos-duration="2000">
        <a href="#" class="card_ventas">
          <p class="title_card_services">Ventas</p>
          <p>
            Venta de todo tipo de inmuebles, tales como: Casas, apartamentos, fincas, lotes, locales,
            bodegas,
            oficinas.
          </p>
          <span class="hover-text">Ver Inmuebles en venta</span>
        </a>
        <a href="#" class="card_arrendamientos">
          <p class="title_card_services">Arrendamientos</p>
          <p>
            Encuentra casas, apartamentos, fincas, apartamentos amoblados por días, semanas o meses.
          </p>
          <span class="hover-text">Ver Inmuebles en Arriendo</span>
        </a>
        <a href="#" class="card_asesorias">
          <p class="title_card_services">Asesorías Jurídicas</p>
          <p>
            Ofrecemos asesoría integral en derecho civil, cobro jurídico, inmobiliario, urbano, constitucional,
            comercial y societario.
          </p>
          <span class="hover-text">Conocer Abogados</span>
        </a>
        <a href="#" class="card_amoblados">
          <p class="title_card_services">Arrendamiento de Amoblados</p>
          <p>
            Contamos con todo tipo de Inmuebles Amoblados, Apartamentos, Casa, Apartaestudio y mucho más.
          </p>
          <span class="hover-text">Ver Inmuebles Amoblados</span>
        </a>
      </div>
    </section>
    <Modal :isOpen="isModalOpen" @close="closeModal">
      <h3>Contenido del modal</h3>
      <div class="modal_content"
        style="display: grid; grid-template-columns: repeat(12, 1fr); padding: 40px;gap: 20px;">
        <div class="imges_estate" style="grid-column: span 7;">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/18.jpg"
            style="width: 100%; height: 100%;border-radius: 5px;" alt="">
        </div>
        <div style="grid-column: span 5;">
          <div class="display_flex">
            <div class="badge_card_properties">ARRIENDO</div>
            <div class="badge_card_properties">ID123456</div>
          </div>
          <h3>Casa Bifamiliar En Venta – Sector Kennedy Cod: 23868</h3>
          <hr>
          <p>$250,000,000</p>
          <hr>
          <p>CASA BIFAMILIAR EN VENTA – SECTOR KENNEDY COD: 23868 Estupenda casa bifamiliar en venta, con un
            área de
            100 metros aproximadamente, sector Kennedy, 3° piso, estrato 2. En su interior encontramos zona
            social
            de sala comedor, cuatro alcobas, tres closets, un baño privado y un baño social enchapados y
            cabinados
            en acrílico, muebles inferiores, calentador de paso; funcional cocina integral con extractor,
            anaqueles
            inferiores, red de gas, zona de ropas y balcón. Piso en cerámica. pintura en excelente estado.
            (NO INCLUYE MOBILIARIO)</p>
          <hr>
          <div class="card_properties_details">
            <span><i class="material-icons">bed</i> 3</span>
            <span><i class="material-icons-outlined">shower</i> 2</span>
            <span><i class="material-icons-outlined">directions_car</i> 1</span>
            <span><i class="material-icons">square_foot</i> 1.695 m²</span>
          </div>
          <hr>
          <p>Año de construcción</p>
          <hr>
          <div style="display: flex;justify-content: center;">
            <button class="btn_model_detailes">Detalles</button>
          </div>
        </div>
      </div>
    </Modal>
    <!-- Seccion de propiedades recien agregadas -->
    <section class="section_properties">
      <div class="title_seccion_properties">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          Propiedades recien agregadas</h1>
        <hr data-aos="fade-right" data-aos-duration="2000">
        <span data-aos="zoom-in" data-aos-duration="2000" style="font-weight: 100; font-size: 3vh;">Descubre los últimos
          inmuebles agregados.</span>
      </div>
      <div class="carousel-properties-container" data-aos="fade-up" data-aos-duration="2000">
        <button class="carousel-btn prev-btn">❮</button>
        <div class="carousel_cards_properties">
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->
          <!-- Repite este bloque para todas tus cards Modal-->
          <div class="card_properties">
            <div class="card-header">
              <div class="card_properties-icons">
                <div class="display_flex">
                  <div class="badge_card_properties">ARRIENDO</div>
                  <div class="badge_card_properties">ID123456</div>
                </div>
              </div>
              <div class="icons_card_header">
                <span class="material-icons icons-click" @click="openModal">fullscreen</span>
                <!-- Modal -->
                <span class="material-icons icons-click">favorite_border</span>
                <span class="material-icons-outlined icons-click" style="color: white;">
share
</span>
              </div>
            </div>
            <div class="card-body-property">
              <div class="type_property">
                <p>APARTAMENTO</p>
              </div>
              <div>
                <p class="price_propierty">2´350,000</p>
              </div>
              <div class="card_content_details">
                <div class="card_properties_details">
                  <span><i class="material-icons">bed</i> 3</span>
                  <span><i class="material-icons-outlined">shower</i> 2</span>
                  <span><i class="material-icons-outlined">directions_car</i> 1</span>
                  <span><i class="material-icons">square_foot</i> 1.695 m²</span>
                </div>
                <hr>
                <div class="content_btn_card">
                  <a class="card_properties_btn btn_mail">
                    <i class="material-icons-outlined" style="margin-right: 5px;">
                      mail
                    </i> Correo
                  </a>
                  <a class="card_properties_btn btn_wpp">
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Termina el bloque -->

        </div>
        <button class="carousel-btn next-btn">❯</button>
      </div>
      <div class="container_btn_generic" data-aos="fade-up" data-aos-duration="2000">
        <button class="btn-myPrimary">
          Ver inmuebles
        </button>
      </div>
    </section>

    <!-- Seccion tipo inmuebles -->
    <section class="section_type_property">
      <div class="title_seccion_typeProperties">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          Tipo de Inmuebles</h1>
        <hr data-aos="fade-right" data-aos-duration="2000">
        <span data-aos="zoom-in" data-aos-duration="2000" style="font-weight: 100; font-size: 3vh;">Descubre nuestra
          amplia selección
          de inmuebles disponibles
          para ti.</span>
      </div>
      <div class="grid-container">
        <div class="item item_1" data-aos="fade-up-right" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Apartamento
            </p>
          </div>
        </div>
        <div class="item item_2" data-aos="fade-up-right" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Finca recreo
            </p>
          </div>
        </div>
        <div class="item item_3" data-aos="fade-up-right" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Oficina
            </p>
          </div>
        </div>
        <div class="item item_4" data-aos="fade-up" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Casa
            </p>
          </div>
        </div>
        <div class="item item_5" data-aos="fade-up" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Bodega
            </p>
          </div>
        </div>
        <div class="item item_6" data-aos="fade-up-left" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Casa Bifamiliar
            </p>
          </div>
        </div>
        <div class="item item_7" data-aos="fade-up-left" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Local
            </p>
          </div>
        </div>
        <div class="item item_8" data-aos="fade-up-left" data-aos-duration="2000">
          <div class="card_text_typeProperty">
            <p class="text_property">1Propiedad</p>
            <p> Apartaestudio
            </p>
          </div>
        </div>
      </div>
      <div class="container_btn_generic animation_bottom3s">
        <button class="btn-myPrimary" data-aos="fade-up" data-aos-duration="2000">
          Ver tipo de Inmuebles
        </button>
      </div>
    </section>

    <!-- Seccion aliados -->
    <section class="section_allies">
      <div class="title_seccion_allies">
        <h1 data-aos="fade-up" data-aos-duration="1000">
          Aliados </h1>
        <hr data-aos="fade-right" data-aos-duration="2000">
      </div>
      <div class="carousel" data-aos="fade-left" data-aos-duration="2000">
        <div class="carousel-track">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_datacredito.webp" alt="logo_datacredito
          https://web.inmotech.co/wp-content/uploads/2024/07/logo_datacredito.webp">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_DeBTayrona_software_grande.webp"
            alt="Logo 3">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_fianzacredito.webp" alt="Logo 4">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_fontur.png" alt="Logo 5">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/08/18defebrerofenalco-696x265-removebg-preview.png"
            alt="Logo 6">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/Logo_Libertador.png" alt="Logo 6">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/08/Credigroup-1.png" alt="Logo 7">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/05-fianza-inmobiliaria-logo.webp" alt="Logo 8">

          <!-- Duplicamos las imágenes para lograr un efecto de cinta infinita -->
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_datacredito.webp" alt="logo_datacredito
https://web.inmotech.co/wp-content/uploads/2024/07/logo_datacredito.webp">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_DeBTayrona_software_grande.webp"
            alt="Logo 3">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_fianzacredito.webp" alt="Logo 4">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/logo_fontur.png" alt="Logo 5">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/08/18defebrerofenalco-696x265-removebg-preview.png"
            alt="Logo 6">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/Logo_Libertador.png" alt="Logo 6">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/08/Credigroup-1.png" alt="Logo 7">
          <img src="https://web.inmotech.co/wp-content/uploads/2024/07/05-fianza-inmobiliaria-logo.webp" alt="Logo 8">
        </div>
      </div>
    </section>
  </div>
</template>
<script>

// import Banner from "@/components/view/banner/Banner";
// import Inmueble from "@/components/view/inmueble/Inmueble";
// import Blog from "@/components/view/blog/Blog";
// import Contact from "@/components/view/form/Contact";
import Modal from './Modal.vue';

export default {
  name: 'Home',
  components: {
    Modal,
    // Banner,
    // Inmueble,
    // Blog,
    // Contact
  },

  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};


</script>

<style>
/* CSS MODAL */
hr {
  border: none;
  background-color: rgb(230, 230, 230);
  height: 1px;
  border-radius: 5px;
}

.modal_content {
  color: gray;
  background-color: white;
}

.btn_model_detailes {
  border: solid #c7932e 0.8px;
  padding: 15px 58px;
  background-color: transparent;
  border-radius: 5px;
  color: #c7932e;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all .3s;
}

.btn_model_detailes:hover {
  background-color: #C7932E;
  color: white;
}

.display_flex {
  display: flex;
  justify-content: end;
}

.badge_card_properties {
  font-size: 15px;
}

.card_properties_details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_properties_details span {
  display: flex;
  align-items: center;
  margin-right: 10%;
}

.card_properties_details .material-icons,
.material-icons-outlined {
  color: #C7932E;
}

/* CSS MODAL FIN */


template {
  font-family: "Plus Jakarta Sans", serif;
}

/* Seccion tipo de propiedad */

.section_type_property {
  margin-top: 100px;
}

.title_seccion_typeProperties {
  text-align: center;
}

.title_seccion_typeProperties h1 {
  font-size: 5vh;
}

.title_seccion_typeProperties span {
  font-size: 3vh;
  font-weight: 400;
}

.title_seccion_typeProperties hr {
  width: 40%;
  height: 2px;
  background-color: #C7932E;
  border: none;

}

/* Collage propiedades */

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 8px;

  margin: 30px 20px;
}

.item {
  border-radius: 3px;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.item::before {
  content: "";
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(61, 61, 61, 0.342);
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.item:hover::before {
  opacity: 0;
}

.card_text_typeProperty {
  margin: 10px 30px;
  position: relative;
  z-index: 2;
}

.card_text_typeProperty p {
  color: white;
  font-size: 4vh;
  font-weight: 700;
  display: block;
}

.text_property {
  font-size: 3vh !important;
  font-weight: 400 !important;

}

/* section services */

.section_services {
  margin-top: 100px;
}

.title_seccion_services,
.title_seccion_typeProperties,
.title_seccion_properties,
.title_seccion_allies {
  text-align: center;
  margin-bottom: 40px;
  color: #152733;
}

.title_seccion_services h1,
.title_seccion_typeProperties h1,
.title_seccion_properties h1,
.title_seccion_allies h1 {
  font-size: 5vh;
}

.title_seccion_services hr,
.title_seccion_properties hr,
.title_seccion_typeProperties hr,
.title_seccion_allies hr {
  width: 30%;
  height: 2px;
  background-color: #C7932E;
  border: none;
  margin: auto;
  margin-top: -7px;
  margin-bottom: 10px;
}

/* Cards services */

.container_cards_services {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
}

.container_cards_services a {
  background-color: #f0f0f0;
  padding: 25px 25px;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;
  color: #152733;
  min-height: 420px;
  min-width: 300px;
  max-width: 300px;
  font-size: 14px;
  line-height: 24px;
}

.title_card_services {
  color: #152733;
  font-weight: 600;
  font-size: 22px;
}

.card_ventas {
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/CARD-PAGINA-WEB.jpg');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  text-decoration: none;
}

.card_ventas {
  display: inline-block;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/CARD-PAGINA-WEB.jpg');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  text-align: left;
  text-decoration: none;
  position: relative;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
}

.card_ventas:hover .hover-text {
  opacity: 1;
  transition: ease-in .3s;
}

.text_hover_card_services {
  display: none;
}


.card_ventas:hover {
  text-decoration: none;
  color: #152733;
}

.card_arrendamientos {
  display: inline-block;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Diseno-sin-titulo-1.jpg');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  text-align: left;
  text-decoration: none;
  position: relative;
}


.card_arrendamientos:hover .hover-text {
  opacity: 1;
  transition: ease-in .3s;
}

.card_arrendamientos:hover {
  text-decoration: none;
  color: #152733;
}


.card_asesorias {
  display: inline-block;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Diseno-sin-titulo-4.jpg');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  text-align: left;
  text-decoration: none;
  position: relative;
}

.card_asesorias:hover .hover-text {
  opacity: 1;
  transition: ease-in .3s;
}

.card_asesorias:hover {
  text-decoration: none;
  color: #152733;
}

.card_amoblados {
  display: inline-block;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/CARD-PAGINA-WEB.png');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  text-align: left;
  text-decoration: none;
  position: relative;
}

.card_amoblados:hover .hover-text {
  opacity: 1;
  transition: ease-in .3s;
}

.card_amoblados:hover {
  text-decoration: none;
  color: #152733;
}


.container_btn_generic {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.display_flex {
  display: flex;
}

.btn_mail {
  width: 70%;
  margin-right: 20px;
  font-size: 15px;
  font-weight: 500;
}

.btn_wpp {
  width: 30%;
  font-size: 22px;
}

.item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.item_1 {
  grid-row: 1/4;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/07/8.jpeg.jpg');
}

.item_2 {
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/WhatsApp-Image-2023-08-05-at-1.57.09-PM-1.jpeg.jpg');
}

.item_3 {
  grid-row: 2/4;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Imagen-de-WhatsApp-2024-08-13-a-las-16.24.44_b197a43d.jpg');
}

.item_4 {
  grid-row: 1/3;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Imagen-de-WhatsApp-2024-08-13-a-las-16.01.10_120557fc.jpg');
}

.item_5 {
  grid-row: 3/4;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Imagen-de-WhatsApp-2024-08-13-a-las-16.08.05_f3e2d48d.jpg');
}

.item_6 {
  grid-row: 1/2;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Imagen-de-WhatsApp-2024-08-13-a-las-16.05.36_02499535.jpg');
}

.item_7 {
  grid-row: 2/4;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/08/Imagen-de-WhatsApp-2024-08-13-a-las-15.31.04_c9ca5068.jpg');
}

.item_8 {
  grid-row: 1/4;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/07/18.jpg');
}

.img_pse {
  width: 120px;
}



/* Seccion propiedades recien agregadas */

.section_properties {
  margin-top: 100px;
}

.title_seccion_properties {
  text-align: center;
}

.title_seccion_properties h1 {
  font-size: 5vh;
}

.title_seccion_properties hr {
  width: 40%;
  height: 2px;
  background-color: #C7932E;
  border: none;

}

.title_seccion_properties span {
  color: #152733;
}

/* carousel */
.carousel-properties-container {
  position: relative;
  width: 90%;
  margin: auto;
  overflow: hidden;
}

.carousel_cards_properties {
  display: flex;
  transition: transform 0.5s ease-in-out;
  gap: 20px;
}

.card_properties {
  flex: 0 0 300px;
  /* Cada tarjeta ocupa 300px de ancho */
  background: #fff;
  border: solid rgb(219, 219, 219) 0.3px;
  border-radius: 10px;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #1527338e;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: point er;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.carousel-btn:hover {
  background: #152733e3;
}


.badge_card_properties {
  background-color: #c7942e80;
  padding: 5px;
  margin: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.card-header {
  height: 200px;
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/07/18.jpg');
  position: relative;
  border-radius: 5px 5px 0px 0px;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}

.card_properties-icons {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
}

.card_properties_details {
  display: flex;
  justify-content: space-between;
  color: #757575;
  margin: 1px 0;
  font-size: 14px;
}

.card_properties_details span {
  display: flex;
  align-items: center;
}

.card_properties_details span i {
  margin-right: 4px;
}

.card_properties_details .material-icons,
.material-icons-outlined {
  color: #C7932E;
}

.icons-click {
  background-color: #20202054;
  margin-right: 8px;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.icons-click:hover {
  transform: scale(1.1);
}

a {
  text-decoration: none;

}

.card_properties_btn {
  border: solid #C7932E 0.8px;
  background-color: white;
  color: #C7932E;
  border-radius: 4px;
  padding: 11px;
  margin: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.card_properties_btn:hover {
  background-color: #c7942e27;
  color: white !important;
  border: solid #dabe87 0.3px;
  color: #152733 !important;
}

.card_properties_btn a {
  color: #C7932E;
}

.card-body-property {
  padding: 10px;
}

.type_property {
  color: #152733;
}

.price_propierty {
  color: #C7932E;
}

.icons_card_header {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  color: white;
  margin: 8px 0px;
}

.card_content_details hr {
  color: #757575;
  height: 0.8px;
  background-color: #b6b6b6;
  border: none;
}

.content_btn_card {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Banner */

.banner {
  box-shadow: 0px 20px 20px 0px rgba(21, 39, 51, 0.37);
  transition: 1s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background-color: transparent;
  border-radius: 16px 16px 16px 16px;
  margin-top: 30px;
}

.header {
  background-image: url('https://web.inmotech.co/wp-content/uploads/2024/07/14.jpg');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px 16px 16px 16px;
}


.container_banner {
  background-image: linear-gradient(249deg, #FFFFFF00 14%, #152733D4 53%);
  border-radius: 18px;
  padding: 11% 5% 11% 5%;
}

.container_text_banner {

  color: #FFFFFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.container_text_banner p {
  font-size: 6vh;
  font-weight: 600;
}

.container_text_banner span {
  font-size: 16px;
}

/* serch banner */

.container_search_banner {
  margin-top: 30px;
}

.search-bar {
  width: 100%;
  max-width: 850px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 0px 8px 8px 8px;
  padding: 10px;
  margin-top: -10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 10px;
  width: 28%;
}

.tab {
  flex: 1;
  padding: 15px 15px;
  text-align: center;
  background: #C7932E;
  border: none;
  border-radius: 4px 4px 0px 0px;
  margin-right: 2px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 300;
  transition: background 0.3s, color 0.3s;
}

.tab:last-child {
  border-right: none;
}

.tab.active {
  background: #f8f8f8;
  color: #272727;
}

.tab:hover {
  background: #d5a56c;
}

.search-form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-input {
  flex: 2;
  padding: 15px 50px 15px 6px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  font-size: 14px;
}

.dropdown {
  flex: 1;
  padding: 15px 50px 15px 6px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  font-size: 14px;
}

.search-button {
  padding: 15px 50px;
  background: #C7932E;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.search-button:hover {
  background: #b3884d;
}


.btn {
  flex: 1;
  padding: 5px 10px;
  background-color: white;
  color: #C7932E;
  border: 1px solid #C7932E;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-myPrimary {
  padding: 10px 21px;
  background-color: #C7932E;
  color: #ffffff;
  border: 1px solid #C7932E;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background-color: #C7932E;
  color: white;
}

.details .material-icons {
  color: #C7932E;
}


/* Carrusel */

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #fff;
  height: 120px;
}

.carousel-track {
  display: flex;
  align-items: center;
  animation: scroll 20s linear infinite;
  /* Duración ajustada para movimiento más suave */
}

.carousel-track img {
  width: 150px;
  height: 100%;
  margin-right: 40px;
  object-fit: contain;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}



@media (max-width: 960px) {
  .search-form {
    display: grid;
    gap: 10px;
    align-items: center;
  }

  .search-bar {
    width: 95%;
  }

  .dropdown {
    flex: 1;
    padding: 15px 50px 15px 6px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    font-size: 14px;
    width: 50%;
  }

  .container_dropdown {
    display: flex;

  }

  .container_dropdown .first_Select {
    margin-right: 4px;

  }

  .container_text_banner p {
    font-size: 4vh;
    font-weight: 600;
  }
}


@media (max-width: 560px) {
  .tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 10px;
    gap: 8px;
    width: 95%;
  }

  .search-bar {
    width: 95%;
    max-width: 850px;
    background: rgba(255, 255, 255, 0.144);
    border: 1px solid #e4e4e4b9;
    border-radius: 2px 2px 8px 8px;
    padding: 10px;
    margin-top: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}


/* Seccion Aliados */

.section_allies {
  margin-top: 80px;
}

.title_seccion_allies {
  text-align: center;
}

.title_seccion_allies h1 {
  font-size: 5vh;
}

.title_seccion_allies hr {
  width: 30%;
  height: 2px;
  background-color: #C7932E;
  border: none;

}

/* RESPONSIVE */

@media (max-width: 1364px) {
  .card_text_typeProperty {
    margin: 10px 10px;
  }

  .card_text_typeProperty p {
    font-size: 3vh;
    font-weight: 600;
  }

  .container_cards_services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 50px 0px;
    gap: 30px;
    align-items: center;
    margin: 0 auto;
  }

  .container_cards_services a {
    max-width: none;
  }
}

@media (max-width: 800px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(9, 1fr);
    gap: 8px;
    margin: 30px 20px;
  }

  .item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .item_1 {
    grid-row: 1/4;
  }

  .item_2 {
    grid-row: 3/4;
  }

  .item_3 {
    grid-row: 1/3;
  }

  .item_4 {
    grid-row: 4/6;
  }

  .item_5 {
    grid-row: 6/7;
  }

  .item_6 {
    grid-row: 4 / 5;
  }

  .item_7 {
    grid-row: 5/7;
  }

  .item_8 {
    grid-row: 7/10;
  }

}

@media (max-width: 690px) {

  .container_cards_services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 50px 0px;
    align-items: center;
    margin: 0 auto;
  }

}
</style>