<template>
	<section class="section-footer">
		<svg xmlns="http://www.w3.org/2000/svg" class="wave" viewBox="0 0 1000 100" preserveAspectRatio="none">
			<path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
        c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
        c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
		</svg>
		<footer>
			<div class="grid_footer_second">
				<div class="container_second_grid_footer">
					<p class="title_footer">
						Contáctanos
					</p>
					<p>
					<ul>
						<li style="display: flex;align-items: center;"> Sede Laureles: Carrera 81 A # 42 B 34</li>
						<li>Medellín, Antioquia</li>
						<li>(604) 444 2949</li>
						<li class="li_links">
							<a target="_blank" class="a_text_footer"
								href="https://api.whatsapp.com/send/?phone=573158356668&text&type=phone_number&app_absent=0">+57
								315 835 6668</a>
						</li>
						<li>pqrs@grupoempresarialmi.com</li>
					</ul>
					</p>
				</div>
				<div class="container_second_grid_footer">
					<p class="title_footer">Información Legal</p>
					<p>
					<ul>
						<li>M.A.V.U N.49/22</li>
						<li>
							<router-link class="link" to="/terminos-condiciones">
								<i class="material-icons-outlined" style="color: white;">
									chevron_right
								</i>
								Términos y condiciones</router-link>
						</li>
						<li><router-link class="link" to="/politicas-privacidad"><i class="material-icons-outlined"
									style="color: white;">
									chevron_right
								</i>


								Políticas de privacidad</router-link></li>
						<li><router-link class="link" to="/preguntas-frecuentes">
								<i class="material-icons-outlined" style="color: white;">
									chevron_right
								</i>
								Preguntas frecuentes</router-link></li>
						<li><router-link class="link" to="/pqrs">
								<i class="material-icons-outlined" style="color: white;">
									chevron_right
								</i>
								PQRS</router-link></li>
						<li>
							Reportar un Pago o Daño
						</li>

					</ul>
					</p>
				</div>
				<div class="container_second_grid_footer">
					<p class="title_footer">Redes Sociales</p>
					<p>
					<ul>
						<li class="li_links">
							<a class="a_text_footer" href="https://www.facebook.com/grupoempresarialmisas"
								target="_blank"> <i class="fa-brands fa-square-facebook"></i> Facebook</a>
						</li>

						<li class="li_links">
							<a class="a_text_footer" href="https://www.instagram.com/grupo_empresarial_mi/"
								target="_blank"><i class="fa-brands fa-instagram"></i> Instagram</a>
						</li>
						<li class="li_links">
							<a class="a_text_footer" href="https://api.whatsapp.com/send?phone=573158356668"
								target="_blank"> <i class="fa-brands fa-whatsapp"></i> WhatsApp</a>
						</li>
						<li class="li_links">
							<a class="a_text_footer" href="https://www.tiktok.com/@grupo_empresarialmi"
								target="_blank"><i class="fa-brands fa-tiktok"></i> TikTok</a>
						</li>
					</ul>
					</p>
				</div>
				<div class="container_second_grid_footer">
					<p class="title_footer">Pagos PSE</p>
					<p>
						Pago con Tarjeta de Crédito
					</p>
					<img style="width: 120px;" src="https://monopolioinmobiliario.co/img/pse_monopolio.png" alt="">
				</div>
			</div>
		</footer>
	</section>
</template>

<script>
import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
import mixinInmuebles from '@/libs/mixins/mixinInmuebles'
export default {

	name: 'MainFooter',
	mixins: [mixinConfiguracion, mixinInmuebles],
	data() {
		return {
			'año': new Date().getFullYear()
		}
	},
	mounted() {
		this.$store.dispatch('AppInmuebles/getListInmuebles');
	}
}
</script>

<style lang="css" scoped>
footer {
	background-color: #152733;
	color: white;
	margin-top: -25px;
}

.container_text_footer a {
	text-decoration: none;
	color: white;
	font-size: 15px;
	font-weight: 300;
}

.li_links>a {
	text-decoration: none;
	color: white;
}

.a_text_footer>i {
	margin-right: 10px;
	font-size: 20px;
}


.link {
	text-decoration: none;
	color: white;
	display: flex;
	align-items: center;
}

.link:hover {
	color: #C7932E;
}


.li_links:hover {
	color: #C7932E;
}

.li_links>a:hover {
	color: #C7932E;
}

.container_text_footer ul {
	margin: 0;
	padding: 0;
}



.grid_footer_first,
.grid_footer_second {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	font-size: 13px;
	font-weight: 300;
	margin: 20px 20px;
	padding: 30px 0px;
}

.container_second_grid_footer ul {
	margin: 0;
	padding: 0;
	line-height: 30px;
}


.title_footer {
	font-size: 22px;
	font-weight: 700;
}

.wave {
	width: 100%;
	height: 80px;
}

.elementor-shape-fill {
	fill: #152733;
}


.section-footer {
	margin-top: 100px
}

li {
	list-style: none;
}

@media (max-width: 1100px) {

	.grid_footer_first,
	.grid_footer_second {
		grid-template-columns: repeat(3, 1fr);
	}



}

@media (max-width: 900px) {

	.grid_footer_first,
	.grid_footer_second {
		grid-template-columns: repeat(2, 1fr);
		margin: 20px 10px;
	}

}

@media (max-width: 650px) {

	.grid_footer_first,
	.grid_footer_second {
		grid-template-columns: repeat(1, 1fr);
		margin: 20px 5px;
	}

}
</style>